<template>
    <content-wrapper>
        <w-data-table
            outlined
            :loading="loading"
            :headers="headers"
            :items="collectionData"
            :filter-by="filterBy"
            :server-items-length="collection.total"
            searchable
            @change:options="fetchItems"
            @click:row="onRowClick"
        >
            <!-- filters -->
            <template #filters>
                <v-select
                    v-model="filterByDepartment"
                    :items="departments"
                    item-text="title"
                    item-value="uuid"
                    :placeholder="$trans('Department')"
                    dense
                    hide-details
                    clearable
                >
                    <template #prepend>
                        <w-icon dense value="DEPARTMENT" />
                    </template>
                </v-select>
            </template>
            <template #actions>
                <w-btn-action
                    :text="$trans('Add course')"
                    icon="ADD"
                    @click="isAsideDrawer = true"
                />
            </template>
            <template #item.title="{ item }">
                <div class="d-flex py-2">
                    <div>
                        <div class="font-weight-bold">{{ item.title }}</div>
                        <small class="caption d-block d-md-none">
                            {{ item.department.title }}
                        </small>
                    </div>
                </div>
            </template>
            <template #[`item.department_uuid`]="{ item }">
                {{ item.department.title }}
            </template>
            <!-- actions -->
            <template #[`item.actions`]>
                <w-icon-next color="primary"/>
            </template>
        </w-data-table>
        <w-aside-drawer
            v-model="isAsideDrawer"
            :title="asideTitle"
        >
            <div class="pa-4">
                <course-form
                    @submit="onRowClick"
                />
            </div>
        </w-aside-drawer>
    </content-wrapper>
</template>

<script>
import { getCoursesCollection } from '@apps/school/api/courses'
import ContentWrapper from '@/components/ContentWrapper'
import hasDepartmentsPropertyMixin from '@apps/school/mixins/hasDepartmentsPropertyMixin'
import hasCurrentSchoolPropertyMixin from '@apps/school/mixins/hasCurrentSchoolPropertyMixin'
import CourseForm from '@apps/school/components/Course/CourseForm'

export default {
    name: 'CoursesManage',
    components: { CourseForm, ContentWrapper },
    mixins: [
        hasDepartmentsPropertyMixin,
        hasCurrentSchoolPropertyMixin
    ],
    computed: {
        asideTitle() {
            return this.$trans('Add course')
        },
        collectionData() {
            return this.collection.data.map(item => {
                return item
            })
        },
        headers() {
            return [
                {
                    text: this.$trans('Course'),
                    value: 'title'
                },
                {
                    text: this.$trans('Department'),
                    value: 'department_uuid'
                },
                {
                    value: 'actions',
                    align: 'right',
                    sortable: false
                }
            ]
        }
    },
    watch: {
        currentSchool(val, oldVal) {
            if(val && !oldVal) {
                this.fetchItems()
            }
        },
        filterByDepartment(uuid) {
            this.fetchItems({ department: uuid })
        }
    },
    data() {
        return {
            isAsideDrawer: false,
            loading: false,
            collection: {
                data: []
            },
            filterBy: ['title'],
            filterByDepartment: null
        }
    },
    methods: {
        async fetchItems(payload) {
            if(!this.currentSchool) return

            this.loading = true

            payload = payload || {}

            payload.school = this.currentSchool.uuid
            payload.department = this.filterByDepartment

            if (!payload.perPage) {
                payload.perPage = 25
            }

            getCoursesCollection(payload)
                .then(response => {
                    this.collection = response.data.data
                    this.loading = false
                })
                .catch(() => {
                    this.loading = false
                })
        },
        onRowClick(item) {
            this.$router.push({
                name: 'manage.school.courses.details',
                params: { uuid: item.uuid }
            })
        },
        onCreated(data) {
            this.isAsideDrawer = false
            this.loading = false
            this.fetchItems()
        }
    }
}
</script>

<style lang=scss>

</style>
