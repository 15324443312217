/* eslint-disable */
import request from '@/plugins/request'

const prefix = 'courses'

export const getCoursesCollection = payload => {
	const options = {
		method: 'GET',
		url: `${prefix}`,
		params: payload
	}

	return request(options)
}

export const getCourse = (uuid, payload) => {
    const options = {
        method: 'GET',
        url: `${prefix}/${uuid}`,
        params: payload
    }

    return request(options)
}


/**
 *
 * @param {Object} data
 * @returns {AxiosPromise}
 */
export const storeCourse = data => {
    const options = {
        method: 'POST',
        url: `${prefix}`,
        data: data
    }

    return request(options)
}

/**
 *
 * @param {String} uuid
 * @param {Object} data
 * @returns {AxiosPromise}
 */
export const updateCourse = (uuid, data) => {
    const options = {
        method: 'PATCH',
        url: `${prefix}/${uuid}`,
        data: data
    }

    return request(options)
}

/**
 *
 * @param {String} uuid
 * @returns {AxiosPromise}
 */
export const deleteCourse = uuid => {
    const options = {
        method: 'DELETE',
        url: `${prefix}/${uuid}`
    }

    return request(options)
}

/**
 *
 * @param {String} uuid
 * @param {String} groupUuid
 * @returns {AxiosPromise}
 */
export const attachGroupToCourse = (uuid, groupUuid) => {
    const options = {
        method: 'PATCH',
        url: `${prefix}/${uuid}/attach/group`,
        data: { group_uuid: groupUuid }
    }

    return request(options)
}

/**
 *
 * @param {String} uuid
 * @param {String} groupUuid
 * @returns {AxiosPromise}
 */
export const detachGroupFromCourse = (uuid, groupUuid) => {
    const options = {
        method: 'PATCH',
        url: `${prefix}/${uuid}/detach/group`,
        data: { group_uuid: groupUuid }
    }

    return request(options)
}
