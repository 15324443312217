import store from '@/store'

export default {
    filters: {
        schoolTitle(uuid) {
            const schools = store.getters['school/schools']
            return schools.find(o => o.uuid === uuid).title
        }
    }
}
