<template>
    <w-simple-form
        :key="keyComponent"
        :disabled="isFormDisabled"
        :fields="formFields"
        :item="item"
        :errors="responseErrors"
        @save="onSave"
        @update="onUpdate"
    />
</template>

<script>
import { required } from '@/utils/validation'
import hasDepartmentsPropertyMixin from '@apps/school/mixins/hasDepartmentsPropertyMixin'
import hasSchoolTitleFilterMixin from '@apps/school/mixins/hasSchoolTitleFilterMixin'
import { storeCourse, updateCourse } from '@apps/school/api/courses'
import { CAPACITY, CLOCK, COURSE, DEPARTMENT } from '@/widgets/components/WIcon/icons'

export default {
    name: 'CourseForm',
    props: {
        item: {
            type: Object,
            default: function () {
                return {}
            }
        }
    },
    mixins: [
        hasDepartmentsPropertyMixin,
        hasSchoolTitleFilterMixin
    ],
    data() {
        return {
            keyComponent: 0,
            icons: {
                departmentIcon: DEPARTMENT,
                courseIcon: COURSE,
                capacity: CAPACITY,
                clock: CLOCK
            },
            isFormDisabled: false,
            responseErrors: {}
        }
    },
    computed: {
        selectGroupsItems() {
            return this.groups.filter(o => this.selectedLevels.includes(o.level_uuid))
        },
        formFields() {
            return [
                {
                    name: 'department_uuid',
                    type: 'v-select',
                    props: {
                        label: this.$trans('Department'),
                        outlined: true,
                        dense: true,
                        required: true,
                        prependInnerIcon: this.icons.departmentIcon,
                        disabled: this.item && Object.keys(this.item).length > 0,
                        rules: [
                            required
                        ],
                        items: this.departments,
                        itemText: 'title',
                        itemValue: 'uuid'
                    }
                },
                {
                    name: 'title',
                    type: 'v-text-field',
                    props: {
                        label: this.$trans('Course'),
                        outlined: true,
                        dense: true,
                        required: true,
                        prependInnerIcon: this.icons.courseIcon,
                        rules: [
                            required
                        ],
                    }
                },
                {
                    name: 'description',
                    type: 'v-textarea',
                    props: {
                        label: this.$trans('Summary'),
                        outlined: true,
                        dense: true,
                        required: true
                    }
                },
                {
                    name: 'total_capacity',
                    type: 'v-text-field',
                    props: {
                        label: this.$trans('Total capacity'),
                        type: 'number',
                        outlined: true,
                        dense: true,
                        required: true,
                        prependInnerIcon: this.icons.clock,
                        suffix: this.$trans('hours')
                    }
                }
            ]
        }
    },
    watch: {
        item() {
            this.keyComponent++
        }
    },
    methods: {
        async onSave(data) {
            this.responseErrors = {}
            this.isFormDisabled = true

            try {
                const response = await storeCourse(data)
                this.isFormDisabled = false
                this.$emit('save', response.data.data)
                this.$emit('submit', response.data.data)
            } catch (e) {
                this.isFormDisabled = false

                if (e.response.data.errors) {
                    this.responseErrors = e.response.data.errors
                }
            }
        },
        async onUpdate(data, item) {
            this.isFormDisabled = true
            try {
                const response = await updateCourse(item.uuid, data)
                this.isFormDisabled = false
                this.$emit('updated', response.data.data)
                this.$emit('submit', response.data.data)
            } catch (error) {
                this.responseErrors = error.response.data.errors || {}
                this.isFormDisabled = false
            }
        }
    }
}
</script>

<style lang=scss>

</style>
